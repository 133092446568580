import React from "react";
import SEO from "../components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import { theme } from "@src/components/nessie-web";
import WallOfLoveHeader from "@src/components/partials/wall-of-love/WallOfLoveHeader";
import WallOfLoveGrid from "@src/components/partials/wall-of-love/WallOfLoveGrid";
import CTASection from "@src/components/CTASection";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";
import wallOfLoveData from "@src/components/partials/wall-of-love/wall-of-love.json";

const {
  colors: { dt_taro10 },
} = theme;

function formatWallOfLoveDate(date: Date) {
  return date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
}

const WallOfLove = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { regex: "/^221024_wall_of_love/" } }) {
        brandedImages: edges {
          node {
            publicURL
          }
        }
      }
      directus {
        page_wall_of_love {
          title
          hero_image {
            id
            filename_disk
          }
          footer_image {
            id
            filename_disk
          }
          app_reviews
        }
        branded_media {
          type
          image {
            filename_disk
          }
          lottie_animation
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    allFile: { brandedImages },
    directus: {
      page_wall_of_love: {
        hero_image: {
          file: { publicURL: heroImageSrc },
        },
        footer_image: {
          file: { publicURL: footerImageSrc },
        },
        app_reviews,
      },
      branded_media,
    },
  } = data;

  const HeaderProps = {
    title: "directus.page_wall_of_love.hero_title",
    subtitle: "directus.page_wall_of_love.hero_subtitle",
    bgImage: heroImageSrc,
    accentBGColor: dt_taro10,
  };

  const CTASectionProps = {
    cta_title: "directus.page_wall_of_love.footer_title",
    cta_button_text: "",
    cta_background_image_url: footerImageSrc,
    cta_advice: "",
    button_log_event_name: "",
    params: {},
    home_variation: true,
    hide_button: true,
    accentBGColor: dt_taro10,
  };

  const reviews = app_reviews.map((r: any) => {
    const stars = [];
    for (let i = Math.floor(r.stars); i > 0; i--) {
      stars.push(100);
      if (i === 1 && r.stars % 1 > 0) stars.push(Math.floor((r.stars % 1) * 100));
    }
    const date = formatWallOfLoveDate(new Date(r.date));
    return { type: "app-review", data: { ...r, stars, date } };
  });

  const WallOfLoveGridProps = {
    gridData: {
      twitter: wallOfLoveData.map((tweet: any) => ({ ...tweet, type: "tweet" })),
      image: brandedImages.map((i: any) => ({
        type: "image",
        data: {
          src: i.node.publicURL,
          alt: "classdo branded image",
        },
      })),
      video: branded_media
        .filter((m: any) => m.type === "video")
        .map((v: any) => ({ type: "video", data: { animationData: v.lottie_animation } })),
      reviews,
    },
  };
  return (
    <>
      <SEO
        title="Wall of Love"
        description="Teachers, families, and kids are the heart of ClassDojo. Here’s just a snippet of the smiles we see every day."
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Wall of Love",
          description:
            "Teachers, families, and kids are the heart of ClassDojo. Here’s just a snippet of the smiles we see every day.",
        }}
      />
      <WallOfLoveHeader {...HeaderProps} />
      <WallOfLoveGrid {...WallOfLoveGridProps} />
      <CTASection {...CTASectionProps} />
    </>
  );
};
export default WallOfLove;
